<template>
  <div class="base-list-page">
    <el-form
      :model="dataForm"
      @keyup.enter.native="getDataList(1)"
      ref="dataForm"
      inline
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item prop="orderId" class="typeInput">
          <el-input
            v-model="dataForm.orderId"
            placeholder="订单id"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="name" class="typeInput">
          <el-input
            v-model="dataForm.name"
            placeholder="微信名字"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="mobile" class="typeInput">
          <el-input
            v-model="dataForm.mobile"
            placeholder="绑定号码"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="shipCompany" class="typeInput">
          <el-input
            v-model="dataForm.shipCompany"
            placeholder="快递公司"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="name" class="typeInput">
          <el-input
            v-model="dataForm.deliveryName"
            placeholder="收货人"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="mobile" class="typeInput">
          <el-input
            v-model="dataForm.deliveryMobile"
            placeholder="收货号码"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="下单日期" class="typeInput">
          <el-date-picker
            v-model="value"
            class="el-popper DatepickerTime"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-select
            v-model="dataForm.status"
            placeholder="类型"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in statusList"
              :key="index"
              :label="item.status"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList(1)">查询</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      border
      stripe
      ref="dataList"
      @select-all="handleSelectAll"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        prop="orderId"
        header-align="center"
        align="center"
        label="订单ID"
      >
      </el-table-column>

      <el-table-column
        prop="employeesName"
        header-align="center"
        align="center"
        label="员工姓名"
      >
      </el-table-column>

      <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="公司"
      >
      </el-table-column>

      <el-table-column
        prop="userName"
        header-align="center"
        align="center"
        label="微信姓名"
      >
      </el-table-column>

      <el-table-column
        prop="mobile"
        header-align="center"
        align="center"
        label="手机号码"
      >
      </el-table-column>

      <el-table-column
        prop="price"
        header-align="center"
        align="center"
        label="价格"
      >
      </el-table-column>

      <el-table-column
        prop="shoppingTime"
        header-align="center"
        align="center"
        label="下单时间"
      >
      </el-table-column>

      <el-table-column
        prop="shipNo"
        header-align="center"
        align="center"
        label="快递编号"
      >
      </el-table-column>

      <el-table-column
        prop="shipCompany"
        header-align="center"
        align="center"
        label="快递公司"
      >
      </el-table-column>

      <el-table-column
        prop="status"
        header-align="center"
        align="center"
        label="状态"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" type="success" size="mini"
            >支付成功</el-tag
          >
          <el-tag v-else-if="scope.row.status === 1" type="warning" size="mini"
            >退款成功</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column
        header-align="center"
        align="center"
        width="220"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('cm:shoppingrecord:findDetail')"
            type="text"
            size="small"
            @click="$dialog('shoppingDetail', scope.row.id)"
            >查看订单详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <list-footer>
      <el-pagination
        slot="right"
        class="right"
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </list-footer>
    <shopping-detail v-if="shoppingDetailVisible" ref="shoppingDetail" />
  </div>
</template>

<script>
import { listMixin, normal } from '@/mixins';
import ShoppingDetail from './shopping-detail';

export default {
  components: { ShoppingDetail },
  mixins: [listMixin, normal],
  data() {
    return {
      dataForm: {
        orderId: '',
        name: '',
        mobile: '',
        shipCompany: '',
        startTime: '',
        endTime: '',
        status: '',
        deliveryName: '',
        deliveryMobile: '',
      },
      value: [],
      statusList: [
        { id: 0, status: '支付成功' },
        { id: 1, status: '退款成功' },
      ],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      shoppingDetailVisible: false,
      shoppingDeliveryVisible: false,
      dataListSelections: [],
    };
  },
  activated() {
    this.getDataList(1);
  },
  created() {},
  methods: {
    // 获取数据列表
    getDataList(page) {
      if (this.value?.length) {
        this.dataForm.startTime = this.value[0];
        this.dataForm.endTime = this.value[1];
      } else {
        this.dataForm.startTime = null;
        this.dataForm.endTime = null;
      }
      this.$api({
        url: '/cm/shoppingrecord/list',
        params: {
          page: page ? page : this.pageIndex,
          limit: this.pageSize,
          ...this.dataForm,
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.dataList = data.page.records;
            this.totalPage = data.page.total;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
        },
      });
    },
  },
};
</script>
